/*====================================================
    header
 ====================================================*/
.logo {
  padding-top: 25px;
  padding-bottom: 25px;
  z-index: 2;
  @media #{$laptop_m_four} {
    text-align: center;
    position: absolute;
    top: -10px;
    left: 50%;
    @include transform(translateX(-50%));
    padding: 0;
  }
  @media #{$small_mobile_four} {
    top: -5px;
  }
}

/*======== main-menu-content =========*/
.main-menu-content {
  padding-top: 20px;
  margin-left: auto;
  text-align: right;
  padding-right: 28px;
  @media #{$laptop_m_four} {
    display: none;
  }
  nav {
    ul {
      li {
        display: inline-block;
        text-transform: capitalize;
        padding-top: 15px;
        padding-right: 23px;
        position: relative;
        a {
          color: $color-light;
          font-size: $default-font-2;
          font-weight: $font-weight-semi-bold;
          display: block;
          @include transition(0.3s);
          position: relative;
          z-index: 1;
          padding: 0 0 28px 0;
          .la-angle-down {
            font-size: $default-font-4;
          }
          &:hover {
            color: $theme-color-2;
          }
        }
        .dropdown-menu-item {
          text-align: left;
          position: absolute;
          top: 60px;
          left: 0;
          width: 200px;
          background-color: $color-light;
          padding-top: 12px;
          padding-bottom: 12px;
          @include border-radius(4px);
          opacity: 0;
          visibility: hidden;
          z-index: 1;
          @include box-shadow(0 0 40px rgba(82, 85, 90, 0.2));
          @include transition(0.3s);
          @include transform(scale(0.9));
          li {
            display: block;
            padding-top: 0;
            padding-right: 0;
            a {
              color: $theme-color-4;
              display: block;
              @include transition(0.3s);
              text-transform: capitalize;
              padding: 5px 25px;
              font-weight: $font-weight-medium;
              position: relative;
              font-size: $default-font-2;
              &:hover {
                color: $theme-color-2;
                padding-left: 30px;
              }
            }
          }
        }
        &:hover {
          .dropdown-menu-item {
            @include transform(scale(1));
            opacity: 1;
            visibility: visible;
          }
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}
/*======== logo-right-content =========*/
.logo-right-content {
  position: relative;
  padding-top: 20px;
  z-index: 1;
  padding-right: 55px;
  @media #{$laptop_m_four} {
    width: 100%;
  }
  .author-access-list {
    @media #{$laptop_m_four} {
      display: none;
    }
    li {
      display: inline-block;
      text-transform: capitalize;
      padding-top: 2px;
      padding-right: 23px;
      &:last-child {
        padding-right: 0;
      }
      a {
        color: $color-light;
        font-weight: $font-weight-semi-bold;
        @include transition(0.3s);
        &:hover {
          color: $theme-color-2;
        }
        &.theme-btn {
          &:hover {
            color: $color-light;
          }
        }
      }
      .or-text {
        font-weight: $font-weight-semi-bold;
        display: inline-block;
        padding: 0 4px;
        text-transform: lowercase;
      }
    }
  }
  .side-menu-open {
    width: 50px;
    padding: 10px;
    cursor: pointer;
    @include border-radius(5px);
    display: none;
    position: absolute;
    right: 0;
    top: 50%;
    @include transform(translateY(-40%));
    background-color: rgba(255, 255, 255, 0.2);
    @media #{$laptop_m_four} {
      display: block;
      right: auto;
      left: 0;
    }
    .menu__bar {
      height: 2px;
      background-color: $color-light;
      display: block;
      @include transition(0.3s);
      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: 5px;
      }
      &:nth-child(2) {
        margin-right: 5px;
      }
    }
    &:hover {
      .menu__bar {
        &:nth-child(2) {
          margin-right: 0;
          margin-left: 5px;
        }
      }
    }
  }
  .side-user-menu-open {
    width: 45px;
    height: 46px;
    background-color: $theme-color-5;
    line-height: 46px;
    text-align: center;
    color: $color-light;
    font-size: $display-3;
    @include border-radius(4px);
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 23px;
    @media #{$laptop_m_four} {
      display: block;
      top: -13px;
    }
  }
}

.header-menu-wrapper {
  &.header-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    @include transition(0.5s);
    @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
    @media #{$laptop_m_four} {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    &:after {
      height: 100%;
    }
  }
  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: $theme-color;
    z-index: -1;
    @include transition(0.5s);
  }
}

.menu-full-width {
  display: flex;
  position: relative;
}

.side-nav-container,
.side-user-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 320px;
  height: 100%;
  background-color: $theme-color;
  overflow-x: hidden;
  z-index: 999;
  @include transition(0.4s cubic-bezier(0.7, 0, 0.2, 1));
  @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
  @include transform(translateX(-100%));
  .humburger-menu-lines {
    display: inline-block;
    text-align: right;
    width: 22px;
    height: 22px;
    cursor: pointer;
    position: absolute;
    top: 25px;
    right: 18px;
    opacity: .5;
    &:before,
    &:after {
      position: absolute;
      content: '';
      height: 2px;
      width: 100%;
      top: 10px;
      left: 0;
      background-color: $color-light;
      @include transform(rotate(-43deg));
      @include transition(0.3s);
    }
    &:after {
      @include transform(rotate(43deg));
    }
    &:hover {
      &:before,
      &:after {
        @include transform(rotate(0));
      }
    }
  }
  .side-menu-wrap {
    margin-top: 100px;
  }
  .side-menu-ul {
    li {
      position: relative;
      &:after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.12);
      }
      @include transition(0.3s);
      a {
        font-size: $default-font;
        color: $color-light;
        display: block;
        text-transform: capitalize;
        font-weight: $font-weight-medium;
        padding: 14px 20px;
        @include transition(0.3s);
        position: relative;
        .la-angle-down {
          float: right;
          width: 30px;
          height: 30px;
          text-align: center;
          line-height: 30px;
          background-color: rgba(255, 255, 255, 0.12);
          display: block;
          position: absolute;
          top: 50%;
          right: 15px;
          @include transform(translateY(-50%));
          font-size: $default-font;
          @include transition(0.3s);
          @include border-radius(50%);
        }
        &:hover {
          .la-angle-down {
            background-color: rgba(255, 255, 255, 0.30);
          }
        }
      }
      .dropdown-menu-item {
        display: none;
        li {
          &:after {
            display: none;
          }
          &:first-child {
            border-top: 1px solid rgba(255, 255, 255, 0.12);
            padding-top: 10px;
          }
          &:last-child {
            padding-bottom: 10px;
          }
          a {
            padding-left: 30px;
            padding-top: 4px;
            padding-bottom: 4px;
            opacity: .8;
            font-size: $default-font-2;
            &:hover {
              opacity: 1;
              padding-left: 34px;
            }
          }
        }
      }
      &.active {
        a {
          .la-angle-down {
            background-color: $color-light;
            color: $theme-color-2;
            &:before {
              content: "\f113";
            }
          }
        }
      }
    }
  }
  .side-nav-button {
    text-align: center;
    margin-top: 30px;
    .theme-btn {
      background-color: rgba(255, 255, 255, 0.30);
      &:hover {
        background-color: $color-light;
        color: $theme-color-2;
      }
      &:last-child {
        margin-left: 10px;
      }
    }
  }
  &.active {
    @include transform(translateX(0));
  }
}

.side-user-panel {
  left: auto;
  right: 0;
  background-color: $color-light;
  @include transform(translateX(100%));
  padding: 30px;
  width: 280px;
  .humburger-menu-lines {
    right: auto;
    left: 20px;
    &:before,
    &:after {
      background-color: $theme-color;
    }
  }
  .side-user-menu-wrap {
    margin-top: 50px;
  }
  .side-user-img {
    text-align: center;
    position: relative;
    img {
      margin-bottom: 17px;
      width: 90px;
      height: 90px;
      @include border-radius(50%);
      border: 4px solid $color-light;
      @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
    }
    .su__name {
      font-size: $display-4;
      color: $theme-color;
      font-weight: $font-weight-semi-bold;
      margin-bottom: 4px;
    }
    .su__meta {
      color: $theme-color-4;
      font-size: $default-font-2;
      font-weight: $font-weight-medium;
    }
    .avatar-icon {
      position: absolute;
      left: 58%;
      top: 63px;
      width: 30px;
      height: 30px;
      background-color: $theme-color-5;
      line-height: 25px;
      @include border-radius(50%);
      font-size: $default-font-2;
      @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
      border: 3px solid $color-light;
      a {
        color: $color-light;
      }
      .tooltip.top .tooltip-inner,
      .tooltip.bottom .tooltip-inner {
        background-color:$theme-color;
        width: 120px;
        padding-top: 6px;
        padding-bottom: 6px;
      }
      .tooltip.top .tooltip-arrow {
        border-top-color: $theme-color;
      }
    }
  }
  .side-menu-ul {
    margin-top: 27px;
    margin-bottom: 30px;
    border-top: 1px solid $color;
    padding-top: 25px;
    li {
      &:after {
        display: none;
      }
      a {
        color: $theme-color;
        font-size: $default-font-2;
        padding: 8px 18px;
        @include border-radius(4px);
        &:hover {
          background-color: $theme-color-2;
          color: $color-light;
        }
        .user-icon {
          font-size: $default-font;
        }
      }
      .dropdown-divider {
        border-top: 1px solid $color;
        margin-top: 12px;
        padding-bottom: 12px;
      }
      &:last-child {
        a {
          .user-icon {
            color: $alter-color-5;
          }
        }
      }
    }
  }
  .side-user-search {
    text-align: center;
    .theme-btn {
      margin-top: 15px;
    }
  }
  &.active {
    @include transform(translateX(0));
  }
}

/*======= header-area ========*/
.header-area {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1010;
  @media #{$laptop_m_four} {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $color-light;
    opacity: 0.1;
  }
}
/*====================================================
    header-area 2
 ====================================================*/
.header-area2 {
  .header-menu-wrapper {
    padding-right: 45px;
    padding-left: 45px;
    @media #{$laptop_m_five} {
      padding-right: 0;
      padding-left: 0;
    }
  }
}
/*====================================================
    header-area 3
 ====================================================*/
.header-area3 {
  .header-menu-wrapper {
    background-color: $color-light;
    @media #{$laptop_m_four} {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
  .main-menu-content {
    nav {
      ul {
        li {
          a {
            color: $theme-color;
            &:hover {
              color: $theme-color-2;
            }
          }
        }
      }
    }
  }
  .logo-right-content {
    .author-access-list {
      li {
        a {
         color: $theme-color;
          &.theme-btn {
            color: $color-light;
          }
        }
      }
    }
    .side-menu-open {
      background-color: $theme-color;
    }
  }
  &:after {
    display: none;
  }
}
/*====================================================
    header-area 4
 ====================================================*/
.header-area4,
.header-area5 {
  background-color: $theme-color;
}