.icon-box {
  background-color: $color-light;
  padding: 71px 30px 32px 30px;
  @include border-radius(8px);
  @include box-shadow(0 10px 40px rgba(82, 85, 90, 0.1));
  @include transition(0.3s);
  margin-bottom: 30px;
  position: relative;
  @media #{$tab_device} {
    margin-bottom: 60px;
  }
  @media #{$large_mobile} {
    margin-bottom: 60px;
  }
  @media #{$small_mobile} {
    margin-bottom: 60px;
  }
  z-index: 1;
  .info-icon {
    width: 85px;
    height: 85px;
    line-height: 85px;
    @include border-radius(50%);
    position: absolute;
    top: 0;
    left: 50%;
    @include transform(translateX(-50%));
    margin-top: -42px;
    margin-left: auto;
    margin-right: auto;
    background-color: $theme-color-2;
    color: $color-light;
    font-size: $primary-font;
    @include box-shadow(0 15px 40px rgba(245, 84, 142, 0.3));
    @include transition(0.3s);
    .info-number {
      position: absolute;
      right: -5px;
      top: 0;
      width: 30px;
      height: 30px;
      line-height: 27px;
      background-color: $theme-color-2;
      border: 2px solid $color-light;
      @include border-radius(50%);
      @include transition(0.3s);
      font-weight: $font-weight-medium;
      font-size: $default-font-2;
    }
  }
  .info__title {
    font-size: $display-3;
    color: $theme-color;
    font-weight: $font-weight-semi-bold;
    margin-bottom: 20px;
  }
  .info__desc {
    font-size: $default-font;
    color: $theme-color-4;
    font-weight: $font-weight-medium;
  }
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.1;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23333f57' fill-opacity='0.4'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }
  &:hover {
    @include transform(translateY(-4px));
    .info-icon,
    .info-number {
      background-color: $theme-color-3;
    }
  }
}

.icon-box-layout {
  @include border-radius(4px);
  padding-top: 40px;
  .info-icon {
    position: inherit;
    left: auto;
    @include transform(translateX(0));
    background-color: $color-light;
    @include box-shadow(0 10px 40px rgba(82, 85, 90, 0.1));
    color: $theme-color-2;
    margin-top: 0;
    margin-bottom: 25px;
  }
  .theme-btn {
    border: 1px solid rgba(51, 63, 87, 0.1);
    line-height: 40px;
    color: $theme-color-4;
    background-color: transparent;
    @include box-shadow(0 0 0 0);
  }
  &:hover {
    border-bottom-color: $theme-color-2;
    @include transform(translateY(0) scale(0.97));
    .info-icon {
      color: $color-light;
      background-color: $theme-color-2;
    }
    .theme-btn {
      border-color: $theme-color-2;
      background-color: $theme-color-2;
      color: $color-light;
    }
  }
}

.icon-box-layout-2 {
  padding: 40px;
  &:after {
    background-image: url("data:image/svg+xml,%3Csvg width='84' height='84' viewBox='0 0 84 84' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23333f57' fill-opacity='0.24'%3E%3Cpath d='M84 23c-4.417 0-8-3.584-8-7.998V8h-7.002C64.58 8 61 4.42 61 0H23c0 4.417-3.584 8-7.998 8H8v7.002C8 19.42 4.42 23 0 23v38c4.417 0 8 3.584 8 7.998V76h7.002C19.42 76 23 79.58 23 84h38c0-4.417 3.584-8 7.998-8H76v-7.002C76 64.58 79.58 61 84 61V23zM59.05 83H43V66.95c5.054-.5 9-4.764 9-9.948V52h5.002c5.18 0 9.446-3.947 9.95-9H83v16.05c-5.054.5-9 4.764-9 9.948V74h-5.002c-5.18 0-9.446 3.947-9.95 9zm-34.1 0H41V66.95c-5.053-.502-9-4.768-9-9.948V52h-5.002c-5.184 0-9.447-3.946-9.95-9H1v16.05c5.053.502 9 4.768 9 9.948V74h5.002c5.184 0 9.447 3.946 9.95 9zm0-82H41v16.05c-5.054.5-9 4.764-9 9.948V32h-5.002c-5.18 0-9.446 3.947-9.95 9H1V24.95c5.054-.5 9-4.764 9-9.948V10h5.002c5.18 0 9.446-3.947 9.95-9zm34.1 0H43v16.05c5.053.502 9 4.768 9 9.948V32h5.002c5.184 0 9.447 3.946 9.95 9H83V24.95c-5.053-.502-9-4.768-9-9.948V10h-5.002c-5.184 0-9.447-3.946-9.95-9zM50 50v7.002C50 61.42 46.42 65 42 65c-4.417 0-8-3.584-8-7.998V50h-7.002C22.58 50 19 46.42 19 42c0-4.417 3.584-8 7.998-8H34v-7.002C34 22.58 37.58 19 42 19c4.417 0 8 3.584 8 7.998V34h7.002C61.42 34 65 37.58 65 42c0 4.417-3.584 8-7.998 8H50z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }
  .info-icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
    top: 40px;
    left: 30px;
    @include transform(translateX(0));
    margin-top: 0;
    background-color: rgba(255, 107, 107, 0.1);
    color: $theme-color-2;
    @include box-shadow(0 0 0 0);
    font-size: $display-2;
  }
  .info-content {
    position: relative;
    padding-left: 55px;
  }
  &:hover {
    @include transform(translateX(0));
    .info-icon {
      background-color: $theme-color-2;
      color: $color-light;
    }
  }
}

.icon-box-layout-3,
.icon-box-layout-4 {
  &:hover {
    @include transform(scale(1));
  }
}

.icon-box-layout-4 {
  padding: 0;
  @include border-radius(0);
  background-color: transparent;
  @include box-shadow(0 0 0 0);
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  @media #{$small_mobile_two} {
    display: block;
    text-align: center;
  }
  .info-icon {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: $display-4;
    position: inherit;
    top: auto;
    left: auto;
    @include transform(translateX(0));
    margin-top: 0;
    margin-left: 0;
    margin-right: 15px;
    @include box-shadow(0 15px 40px rgba(128, 137, 150, 0.3));
    @media #{$small_mobile_two} {
      margin-right: auto;
      margin-bottom: 15px;
      margin-left: auto;
    }
  }
  .info__title {
    margin-bottom: 5px;
  }
  &:after {
    display: none;
  }
}


.info-more-content {
  .info-icon {
    background-color: $theme-color-2;
    @include box-shadow(0 15px 40px rgba(245, 84, 142, 0.3));
    color: $color-light;
  }
  .theme-btn {
    border-color: $theme-color-2;
    background-color: $theme-color-2;
    color: $color-light;
  }
}




.icon-box.icon-box-hover-effect {
  padding-top: 40px;
  z-index: 1;
  position: relative;
  @media #{$tab_device} {
    margin-bottom: 30px;
  }
  @media #{$large_mobile} {
    margin-bottom: 30px;
  }
  @media #{$small_mobile} {
    margin-bottom: 30px;
  }
  .info-icon {
    position: inherit;
    top: auto;
    left: auto;
    @include transform(translateX(0));
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    margin-bottom: 25px;
    &:after {
      background-color: $color-light;
    }
  }
  .info__title,
  .info__desc {
    @include transition(0.3s);
  }
  &:before,
  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include border-radius(8px);
    @include transition(0.4s);
    z-index: -1;
    opacity: 0;
    visibility: hidden;
  }
  &:before {
    background-size: cover;
    background-position: center;
    background-image: url("../images/video-img.jpg");
  }
  &:after {
    background-color: $theme-color;
  }
  &:hover {
    .info__title,
    .info__desc {
      color: $color-light;
    }
    &:before {
      opacity: 1;
    }
    &:before,
    &:after {
      height: 100%;
      visibility: visible;
    }
    &:after {
      opacity: 0.8;
    }
  }
}