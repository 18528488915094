/*====================================================
    team-area
 ====================================================*/
.team-item {
  @include border-radius(4px);
  @include transition(0.3s);
  margin-bottom: 30px;
  @include box-shadow(0 10px 40px rgba(82, 85, 90, 0.1));
  text-align: center;
  .team-img {
    position: relative;
    img {
      width: 100%;
      @include border-radius(4px 4px 0 0);
    }
    .img-svg-shape {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 120px;
      @include transform(translateX(-50%));
      fill: $color-light;
      stroke: none;
    }
  }
  .team-content {
    padding: 30px 0 32px 0;
    .team__title {
      font-size: $display-4;
      font-weight: $font-weight-semi-bold;
      color: $theme-color;
      margin-bottom: 3px;
      @include transition(0.3s);
      a {
        color: $theme-color;
        @include transition(0.3s);
      }
      &:hover {
        color: $theme-color-2;
        a {
          color: $theme-color-2;
        }
      }
    }
    .team__meta {
      font-size: $default-font-3;
      color: $theme-color-4;
      font-weight: $font-weight-medium;
    }
    .team-social {
      .social-profile {
        li {
          a {
            width: 33px;
            height: 33px;
            line-height: 33px;
          }
        }
      }
    }
  }
  &:hover {
    @include transform(translateY(-4px));
  }
}