/*====================================================
    hiw-area
 ====================================================*/
.hiw-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 955px;
    background-color: $color-light-grey;
    z-index: -1;
    @media #{$tab_device} {
      height: 1255px;
    }
    @media #{$large_mobile} {
      height: 1200px;
    }
    @media #{$small_mobile} {
      height: 1295px;
    }
    @media #{$small_mobile_two} {
      height: 1345px;
    }
  }
}
.add-business-item {
  position: absolute;
  left: 50%;
  @include transform(translateX(-50%));
  bottom: -15%;
  width: 33%;
  background-color: $color-light;
  @include border-radius(4px);
  @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
  @include transition(0.3s);
  border: 1px dashed $color;
  @media #{$tab_device} {
    width: 50%;
    bottom: -10%;
  }
  @media #{$large_mobile_2} {
    position: inherit;
    top: auto;
    bottom: auto;
    left: auto;
    @include transform(translateX(0));
    width: 62%;
    margin-left: auto;
    margin-right: auto;
  }
  @media #{$large_mobile_three} {
    position: inherit;
    top: auto;
    bottom: auto;
    left: auto;
    @include transform(translateX(0));
    width: auto;
    margin-left: 15px;
    margin-right: 15px;
    padding-right: 15px;
    padding-left: 15px;
  }
  a {
    display: block;
    width: 100%;
    padding: 35px 20px 27px 20px;
    span {
      font-size: $display;
      width: 65px;
      height: 65px;
      line-height: 65px;
      margin-bottom: 20px;
      background-color: rgba(128, 137, 150, 0.2);
      color: $theme-color-4;
      @include border-radius(50%);
    }
    p {
      color: $theme-color-4;
      font-size: $default-font;
      font-weight: $font-weight-medium;

    }
  }
  &:hover {
    @include transform(translateX(-50%) scale(1.02));
    @media #{$large_mobile} {
      @include transform(translateX(0) scale(1.02));
    }
    @media #{$small_mobile} {
      @include transform(translateX(0) scale(1.02));
    }
  }
}
.hiw-video-btn {
  position: relative;
  @include box-shadow(0 10px 40px rgba(82, 85, 90, 0.1));
  @include transition(0.3s);
  z-index: 1;
  @media #{$tab_device} {
    margin-top: 0;
  }
  @media #{$large_mobile} {
    margin-top: 0;
  }
  @media #{$small_mobile} {
    margin-top: 0;
  }
  img {
    width: 100%;
    @include border-radius(4px);
  }
  &:before {
    position: absolute;
    content: '';
    bottom: -40px;
    right: -41px;
    width: 45%;
    height: 45%;
    background-image: url("../images/dots.png");
    z-index: -1;
    background-size: cover;
    background-position: center;
    opacity: 0.1;
    @media #{$small_mobile_four} {
      right: auto;
      left: -36px;
      bottom: -18px;
      width: 60%;
    }
  }
}
.video-text {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: $color-light;
  color: $theme-color;
  text-transform: capitalize;
  padding: 38px 110px 32px 113px;
  @include border-radius(0 4px 0 4px);
  @media #{$small_mobile_four} {
    padding: 25px 20px 19px 20px;
  }
  .video__desc {
    font-size: $display-4;
    font-weight: $font-weight-medium;
    margin-top: 15px;
    @media #{$small_mobile_four} {
      font-size: $default-font;
    }
  }
  &:after {
    position: absolute;
    content: '';
    bottom: -26px;
    left: -24px;
    width: 35%;
    height: 80%;
    background-image: url("../images/dots.png");
    background-size: cover;
    background-position: center;
    opacity: 0.3;
    @media #{$small_mobile_four} {
      display: none;
    }
  }
}

/*====================================================
    earn-money-area
 ====================================================*/
.earn-money-area {
  &:after {
    top: auto;
    bottom: 0;
    height: 580px;
    @media #{$laptop_m_four} {
      height: 606px;
    }
    @media #{$tab_device} {
      height: 908px;
    }
    @media #{$large_mobile} {
      height: 1164px;
    }
    @media #{$small_mobile_four} {
      height: 1224px;
    }
    @media #{$small_mobile_two} {
      height: 1266px;
    }
  }

  .video-text {
    left: auto;
    right: 0;
    @include border-radius(100px 0 8px 100px);
    padding: 20px 30px 20px 25px;
    display: flex;
    align-items: center;
    @media #{$small_mobile_two} {
      padding: 15px;
    }
    .video-popup-btn {
      @media #{$small_mobile_two} {
        width: 50px;
        height: 50px;
        line-height: 50px;
        font-size: $display-13;
      }
      &:after {
        @media #{$small_mobile_two} {
          width: 12px;
          height: 12px;
        }
      }
    }
    .video__desc {
      display: inline-block;
      margin-top: 0;
      margin-left: 20px;
      @media #{$small_mobile_two} {
        font-size: $default-font-2;
        margin-left: 10px;
      }
    }
    &:after {
      display: none;
    }
  }
}

/*====================================================
   get-started-area
 ====================================================*/
.get-started-area {
  .hiw-video-btn {
    .video-text {
      right: auto;
      left: 0;
      @include border-radius(0 100px 100px 0);
      .video__desc {
        margin-left: 0;
        margin-right: 20px;
      }
    }
  }
  &:after {
    bottom: auto;
    top: 0;
    height: 1050px;
    @media #{$tab_device} {
      height: 1150px;
    }
    @media #{$large_mobile} {
      height: 1750px;
    }
    @media #{$small_mobile} {
      height: 1150px;
    }
  }
}