/*====================================================
    hero-wrapper
 ====================================================*/
.hero-wrapper {
  background-image: url("../images/hero-bg.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  width: 100%;
  z-index: 1;
  color: $color-light;
  text-align: center;
  padding-top: 280px;
  padding-bottom: 240px;
  @media #{$tab_device} {
    text-align: left;
    padding-top: 200px;
    padding-bottom: 140px;
  }
  @media #{$large_mobile} {
    text-align: left;
    padding-top: 200px;
    padding-bottom: 140px;
  }
  @media #{$small_mobile} {
    text-align: left;
    padding-top: 200px;
    padding-bottom: 115px;
  }
  .section-heading {
    .sec__desc {
      font-size: $display-9;
      margin-top: 15px;
      line-height: inherit;
      @media #{$tab_device} {
        font-size: $display-4;
      }
      @media #{$large_mobile} {
        font-size: $display-4;
        line-height: 27px;
      }
      @media #{$small_mobile} {
        font-size: $display-4;
        line-height: 27px;
      }
    }
    .sec__title {
      font-size: $display-12;
      text-transform: capitalize;
      margin-bottom: 0;
      color: $color-light;
      @media #{$tab_device} {
        font-size: $display-10;
      }
      @media #{$large_mobile} {
        font-size: $display-10;
      }
      .cd-words-wrapper {
        @media #{$tab_device_two} {
          display: block;
          width: 100%;
        }
        @media #{$extra-device} {
          display: inline-block;
        }
        @media #{$small_mobile_five} {
          display: block;
          width: 100%;
        }
        @media #{$small_mobile} {
          display: inline-block;
        }
      }
      @media #{$small_mobile} {
        font-size: $display-5;
      }
      &:before {
        display: none;
      }
    }
  }
  .category-item {
    width: 120px;
    @media #{$large_mobile} {
      width: 45%;
    }
    @media #{$small_mobile} {
      width: 45%;
    }
  }
  .highlight-lists {
    @media #{$large_mobile} {
      flex-wrap: wrap;
    }
    @media #{$small_mobile} {
      flex-wrap: wrap;
    }
  }
  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.9;
    background-color: $theme-color;
  }
}

.main-search-input {
  margin-top: 50px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  background-color: $color-light;
  padding: 20px 25px;
  @include border-radius(4px);
  @media #{$tab_device} {
    margin-top: 35px;
    display: block;
    background-color: transparent;
    padding: 0;
    @include border-radius(0 0 0 0);
  }
  @media #{$large_mobile} {
    margin-top: 35px;
    display: block;
    background-color: transparent;
    padding: 0;
    @include border-radius(0 0 0 0);
  }
  @media #{$small_mobile} {
    margin-top: 35px;
    display: block;
    background-color: transparent;
    padding: 0;
    @include border-radius(0 0 0 0);
  }
  .main-search-input-item {
    flex: 2;
    margin-right: 6px;
    @media #{$tab_device} {
      margin-right: 0;
      margin-bottom: 10px;
    }
    @media #{$large_mobile} {
      margin-right: 0;
      margin-bottom: 10px;
    }
    @media #{$small_mobile} {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
  .main-search-input-btn {
    @media #{$tab_device} {
      margin-top: 20px;
    }
    @media #{$large_mobile} {
      margin-top: 20px;
    }
    @media #{$small_mobile} {
      margin-top: 20px;
    }
    .theme-btn {
      border: 0;
      @media #{$tab_device} {
        width: 100%;
        display: block;
      }
      @media #{$large_mobile} {
        width: 100%;
        display: block;
      }
      @media #{$small_mobile} {
        width: 100%;
        display: block;
      }
    }
  }
}

.highlighted-categories {
  margin-top: 49px;
  @media #{$tab_device} {
    text-align: center;
  }
  @media #{$large_mobile} {
    text-align: center;
  }
  @media #{$small_mobile} {
    text-align: center;
  }
}
.highlighted-element {
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
  font-size: $default-font;
  font-weight: $font-weight-semi-bold;
  margin-bottom: 20px;
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(245, 84, 142, 0.5);
    z-index: -1;
    @include border-radius(50%);
  }
  &:after {
    position: absolute;
    content: '';
    right: 0;
    top: 0;
    width: 12px;
    height: 12px;
    opacity: 0.9;
    background-color: $theme-color;
    @include border-radius(50%);
  }
}
.highlighted__title {
  font-size: $default-font;
  text-transform: capitalize;
}

/*====================================================
    hero-wrapper2
 ====================================================*/
.hero-wrapper2 {
  background-image: url("../images/hero-bg2.jpg");
  text-align: left;
  padding-bottom: 230px;
  @media #{$tab_device} {
    padding-bottom: 150px;
  }
  @media #{$large_mobile} {
    padding-bottom: 150px;
  }
  @media #{$small_mobile} {
    padding-bottom: 140px;
  }
  .section-heading {
    .sec__desc {
      color: rgba(255, 255, 255, 0.7);
    }
  }
  .highlighted-categories {
    @media #{$tab_device} {
      text-align: left;
    }
    @media #{$large_mobile} {
      text-align: left;
    }
  }
  .category-item {
    width: auto;
    padding: 10px 18px 10px 50px;
    margin-left: 0;
    margin-bottom: 10px;
    .icon-element {
      color: $theme-color-2;
      width: auto;
      height: auto;
      @include border-radius(0 0 0 0);
      line-height: 0;
      background-color: transparent;
      display: inline-block;
      margin-bottom: 0;
      position: absolute;
      left: 20px;
      top: 50%;
      @include transform(translateY(-50%));
      &:after {
        display: none;
      }
    }
    &:hover {
      .icon-element {
        color: $color-light;
        background-color: transparent;
      }
    }
  }
  .highlight-lists {
    @media #{$tab_device} {
      flex-wrap: wrap;
    }
  }
  &:after {
    opacity: 0.70;
  }
  &:before {
    position: absolute;
    content: '';
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url("../images/dots2.png");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    opacity: 0.4;
  }
}

/*====================================================
    hero-wrapper3
 ====================================================*/
.hero-wrapper3 {
  background-image: url("../images/hero-bg3.jpg");
  padding-top: 330px;
  padding-bottom: 255px;
  @media #{$tab_device} {
    padding-top: 200px;
    padding-bottom: 145px;
  }
  @media #{$large_mobile} {
    padding-top: 200px;
    padding-bottom: 145px;
  }
  @media #{$small_mobile} {
    padding-top: 200px;
    padding-bottom: 145px;
  }
  .section-heading {
    margin-bottom: 50px;
    @media #{$tab_device} {
      margin-bottom: 30px;
    }
    @media #{$large_mobile} {
      margin-bottom: 30px;
    }
    @media #{$small_mobile} {
      margin-bottom: 30px;
    }
    .sec__title {
      font-size: $display-8;
      @media #{$tab_device} {
        font-size: $display-5;
      }
      @media #{$large_mobile} {
        font-size: $display-5;
      }
      @media #{$small_mobile} {
        font-size: $display-5;
      }
    }
  }
  .tab-shared {
    .nav-tabs {
      li {
        @media #{$large_mobile} {
          margin-bottom: 12px;
        }
        @media #{$small_mobile} {
          margin-bottom: 12px;
        }
        &:last-child {
          @media #{$large_mobile} {
            margin-bottom: 0;
          }
          @media #{$small_mobile} {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  &:after {
    opacity: 0.9;
  }
}
/*====================================================
    hero-wrapper4
 ====================================================*/
.hero-wrapper4 {
  background-image: none;
  padding-top: 180px;
  padding-bottom: 180px;
  .hero-svg-content {
    position: absolute;
    bottom: -42px;
    left: 50%;
    @include transform(translateX(-50%));
    width: 210px;
    height: 100px;
    line-height: 90px;
    i {
      color: $theme-color;
      position: relative;
      z-index: 1;
      font-size: $display-14;
    }
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("../images/hero-shape.png");
      top: 0;
      left: 0;
      z-index: -1;
    }
  }
  .hero-svg-shpae {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 200px;
    fill: $color-light;
    @include transform(translateX(-50%));
  }
  .video-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
  }
  &:after {
    opacity: 0.7;
  }
}

/*====================================================
    hero-wrapper4
 ====================================================*/
.hero-wrapper5 {
  position: relative;
  &:after {
    position: absolute;
    content: "";
    width: 40px;
    height: 40px;
    background-color: $color-light-grey;
    bottom: -20px;
    left: 50%;
    @include transform(translateX(-50%) rotate(45deg));
  }
}









