/*====================================================
    dashboard-nav
 ====================================================*/
.dashboard-nav {
  background-color: $color-light;
  padding: 30px;
  @include border-radius(4px);
  @include box-shadow(0 0 40px rgba(82,85,90,0.1));
  border: 1px solid rgba(128, 137, 150, 0.2);
  @media #{$large_mobile} {
    display: block !important;
  }
  @media #{$small_mobile} {
    display: block !important;
  }
  .theme-btn {
    color: $theme-color;
    background-color: $color-light;
    border: 1px solid rgba(128, 137, 150, 0.3);
    line-height: 44px;
    position: relative;
    padding-right: 20px;
    padding-left: 20px;
    @media #{$tab_device} {
      margin-bottom: 10px;
    }
    @media #{$large_mobile} {
      margin-bottom: 10px;
    }
    @media #{$small_mobile} {
      margin-bottom: 10px;
    }
    &:hover {
      background-color: $theme-color-2;
      color: $color-light;
      border-color: $theme-color-2;
    }
  }
  .btn-box {
    @media #{$tab_device} {
     text-align: right;
    }
    @media #{$large_mobile} {
      margin-top: 20px;
    }
    @media #{$small_mobile} {
      margin-top: 20px;
    }
  }
  .nav-tabs {
    .nav-link {
      &:before {
        position: absolute;
        content: '';
        bottom: 0;
        left: 50%;
        @include transform(translateX(-50%) rotate(45deg));
        width: 12px;
        height: 12px;
        opacity: 0;
        visibility: hidden;
        background-color: $theme-color-2;
        @include transition(0.3s);
      }
      &.active {
        background-color: $theme-color-2;
        color: $color-light;
        border-color: $theme-color-2;
      }
      &.active {
        &:before {
          opacity: 1;
          visibility: visible;
          bottom: -6px;
        }
      }
    }
  }
}

.user-profile-action {
  @media #{$tab_device} {
    margin-bottom: 50px;
  }
  @media #{$large_mobile} {
    margin-bottom: 50px;
  }
  @media #{$small_mobile} {
    margin-bottom: 50px;
  }

  .user-pro-img {
    position: relative;
    img {
      width: 100%;
    }
    .edit-btn {
      position: absolute;
      left: 10px;
      bottom: 10px;
      background-color: $theme-color-4;
      cursor: pointer;
      line-height: 38px;
      padding-right: 12px;
      padding-left: 12px;
      outline: none;
    }
    .dropdown-menu {
      @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
      padding: 20px;
      @include border-radius(4px);
      border: 1px solid rgba(128, 137, 150, 0.2);
      width: 225px;
      margin: 17px 0 0;
      .upload-btn-box {
        .jFiler-theme-default {
          .jFiler-input {
            width: 100%;
            .jFiler-input-button {
              &:after {
                padding-left: 20px;
              }
            }
          }
        }
      }
      &:before {
        position: absolute;
        content: '';
        top: -6px;
        left: 20px;
        width: 12px;
        height: 12px;
        background-color: $color-light;
        border-top: 1px solid rgba(128, 137, 150, 0.2);
        border-left: 1px solid rgba(128, 137, 150, 0.2);
        @include transform(rotate(45deg));
      }
    }
  }
}

.user-edit-form {
  .edit-form-btn {
    color: $theme-color;
    background-color: rgba(128, 137, 150, 0.2);
    border: 1px solid rgba(128, 137, 150, 0.3);
    line-height: 44px;
    position: relative;
    outline: none;
    &:hover {
      background-color: rgba(128, 137, 150, 0.4);;
      border-color: rgba(128, 137, 150, 0.6);;
    }
  }
  .dropdown-menu {
    min-width: 100%;
    margin-top: 15px;
    border: 1px solid rgba(128, 137, 150, 0.2);
    padding: 20px;
    .theme-btn {
      line-height: 40px;
    }
    &:before {
      position: absolute;
      content: '';
      top: -6px;
      left: 50%;
      width: 12px;
      height: 12px;
      background-color: $color-light;
      border-top: 1px solid rgba(128, 137, 150, 0.2);
      border-left: 1px solid rgba(128, 137, 150, 0.2);
      @include transform(translateX(-50%) rotate(45deg));
    }
  }
}

.delete-info-content {
  .theme-btn {
    outline: none;
  }
  p {
    font-weight: $font-weight-medium;
    span {
      font-weight: $font-weight-semi-bold;
    }
  }
}