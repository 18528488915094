/*====================================================
    testimonial-area
 ====================================================*/
.testimonial-area {
  position: relative;
  overflow: hidden;
  z-index: 1;
  .random-img {
    width: 80px;
    height: 80px;
    @include border-radius(50%);
    position: absolute;
    top: 0;
    left: 0;
    @include box-shadow(0 10px 30px rgba(255, 77, 121, 0.1));
    @media #{$tab_device} {
      display: none;
    }
    @media #{$large_mobile} {
      display: none;
    }
    @media #{$small_mobile} {
      display: none;
    }
    &:nth-child(1) {
      top: 4%;
      left: 2%;
    }
    &:nth-child(2) {
      top: 47%;
      left: 6%;
    }
    &:nth-child(3) {
      top: auto;
      bottom: 10%;
      left: 20%;
    }
    &:nth-child(4) {
      top: -10px;
      left: auto;
      right: -20px;
    }
    &:nth-child(5) {
      top: 33%;
      left: auto;
      right: 20%;
    }
    &:nth-child(6) {
      top: auto;
      left: auto;
      right: 5%;
      bottom: 15%;
    }
  }
}

/*======= testimonial-carousel ========*/
.testimonial-carousel {
  position: relative;
  .owl-stage-outer {
    padding: 30px 0;
  }
  .testimonial-item {
    .testi__img {
      width: 90px;
    }
  }
  .owl-nav {
    div {
      position: absolute;
      top: 50%;
      left: -32px;
      @include transform(translateY(-50%));
      width: 45px;
      height: 45px;
      line-height: 40px;
      border: 2px solid rgba(39, 43, 65, 0.2);
      @include border-radius(50%);
      text-align: center;
      font-size: $display-2;
      color: rgba(39, 43, 65, 0.2);
      @include transition(0.3s);
      @media #{$tab_device} {
       display: none;
      }
      @media #{$large_mobile} {
        display: none;
      }
      @media #{$small_mobile} {
        display: none;
      }
      &.owl-next {
        left: auto;
        right: -32px;
      }
      &:hover {
        background-color: $theme-color-2;
        border-color: $theme-color-2;
        color: $color-light;
      }
    }
  }
  .owl-dots {
    width: 100%;
    text-align: center;
    div {
      width: 14px;
      height: 14px;
      @include border-radius(50%);
      border: 2px solid rgba(51, 63, 87, 0.1);
      display: inline-block;
      @include transition(0.3s);
      margin: 0 2px;
      @include transform(scale(0.8));
      &.active {
        @include transform(scale(1));
        background-color: $theme-color-2;
        border-color: $theme-color-2;
      }
    }
  }
}

/*========= testimonial-item =========*/
.testimonial-item {
  .testi__img {
    width: 90px;
    height: 90px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    @include border-radius(50%);
    @include box-shadow(0 0 40px rgba(82, 85, 90, 0.2));
    img {
      width: 100%;
      height: 100%;
      @include border-radius(50%);
    }
  }
  .testi-comment {
    background-color: $color-light;
    @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
    @include border-radius(8px);
    position: relative;
    padding: 30px 30px 33px 30px;
    margin-bottom: 42px;
    z-index: 1;
    .testi__desc {
      font-size: $display-3;
      color: $theme-color-4;
      line-height: 32px;
      font-weight: $font-weight-medium;
    }
    &:before {
      position: absolute;
      content: "";
      bottom: -10px;
      left: 50%;
      @include transform(translateX(-50%) rotate(45deg));
      width: 18px;
      height: 18px;
      background-color: $color-light;
    }
    &:after {
      position: absolute;
      content: "\f10d";
      top: 50%;
      left: 50%;
      @include transform(translate(-50%, -50%));
      font-family: "FontAwesome";
      color: transparent;
      opacity: 0.1;
      z-index: -1;
      font-size: 100px;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: $theme-color;
      @media #{$large_mobile_three} {
        font-size: 70px;
      }
    }
  }
  .tesi__title {
    font-size: $display-4;
    font-weight: $font-weight-bold;
    margin-top: 17px;
    margin-bottom: 15px;
    color: $theme-color;
  }
  .testi__meta {
    font-size: $default-font-3;
    text-transform: capitalize;
    font-weight: $font-weight-medium;
    color: $theme-color-4;
    border: 1px solid $color;
    padding: 6px 17px;
    @include border-radius(30px);
  }
}

/*===== testimonial-area2 ======*/
.testimonial-area2 {
  padding-top: 173px;
  @media #{$large_mobile} {
    padding-top: 96px;
  }
  @media #{$small_mobile} {
    padding-top: 96px;
  }
}