/*====================================================
    about-area
 ====================================================*/
.about-content {
  @media #{$tab_device} {
    margin-bottom: 50px;
  }
  @media #{$large_mobile} {
    margin-bottom: 50px;
  }
  @media #{$small_mobile} {
    margin-bottom: 50px;
  }
}