/*====================================================
    funfact-area
 ====================================================*/
.counter-item {
  position: relative;
  z-index: 1;
  margin-bottom: 50px;
  .counter-number {
    width: 135px;
    height: 135px;
    line-height: 135px;
    background-color: $color;
    margin-left: auto;
    margin-right: auto;
    @include border-radius(50%);
    position: relative;
    &:after {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      width: 30px;
      height: 30px;
      background-color: $theme-color;
      @include border-radius(50%);
    }
    span {
      font-size: $display-5;
      font-weight: $font-weight-bold;
    }
  }
  .counter__title {
    font-size: $display-4;
    font-weight: $font-weight-semi-bold;
  }
}

.fun-bg1 {
  background-color: $color-4 !important;
}
.fun-bg2 {
  background-color: $color-5 !important;
}
.fun-bg3 {
  background-color: $color-6 !important;
}
.fun-bg4 {
  background-color: $color-7 !important;
}

.counter-item.counter-item-layout-2 {
  background-color: $color-light;
  @include border-radius(8px);
  padding: 32px 20px 26px 20px;
  position: relative;
  z-index: 1;
  @include transition(0.3s);
  .counter-number {
    background-color: transparent;
    color: $color-light;
    width: auto;
    height: auto;
    line-height: inherit;
    @include border-radius(0 0 0 0);
    span {
      font-weight: $font-weight-bold;
    }
    &:after {
      display: none;
    }
  }
  .theme-btn {
    background-color: rgba(51, 63, 87, 0.1);
    font-size: $default-font-3;
    color: $theme-color;
    line-height: 35px;
    @include box-shadow(0 0 0 0);
  }
  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 124px;
    top: 0;
    left: 0;
    background-color: $color;
    z-index: -1;
    @include border-radius(8px 8px 0 0);
  }
  &:hover {
    @include transform(translateY(-4px));
    .theme-btn {
      background-color: $theme-color;
      color: $color-light;
    }
  }
}
.counter-item-layout-2.count-bg-1 {
  &:before {
    background-color: $color-3;
  }
}
.counter-item-layout-2.count-bg-2 {
  &:before {
    background-color: $color-4;
  }
}
.counter-item-layout-2.count-bg-3 {
  &:before {
    background-color: $color-5;
  }
}
.counter-item-layout-2.count-bg-4 {
  &:before {
    background-color: $color-6;
  }
}

.funfact-area {
  color: $color-light;
  position: relative;
  overflow: hidden;
  &:before,
  &:after {
    position: absolute;
    content: '';
    top: -5px;
    left: -10px;
    width: 20%;
    height: 50%;
    background-image: url("../images/dots.png");
    background-size: cover;
    background-position: center;
    opacity: 0.8;
    @include transition(0.7s);
    @media #{$tab_device_two} {
      display: none;
    }
  }
  &:after {
    left: auto;
    right: -10px;
    top: auto;
    bottom: -5px;
  }
}