.section-padding {
  padding-top: 100px;
  padding-bottom: 100px;
}

.padding-top-200px {
  padding-top: 200px;
}
.padding-top-190px {
  padding-top: 190px;
}
.padding-top-180px {
  padding-top: 180px;
}
.padding-top-170px {
  padding-top: 170px;
}
.padding-top-160px {
  padding-top: 160px;
}
.padding-top-150px {
  padding-top: 150px;
}
.padding-top-140px {
  padding-top: 140px;
}
.padding-top-130px {
  padding-top: 130px;
}.padding-top-120px {
  padding-top: 120px;
}
.padding-top-110px {
  padding-top: 110px;
}
.padding-top-100px {
  padding-top: 100px;
}
.padding-top-95px {
  padding-top: 95px;
}
.padding-top-90px {
  padding-top: 90px;
}
.padding-top-85px {
  padding-top: 85px;
}
.padding-top-80px {
  padding-top: 80px;
}
.padding-top-70px {
  padding-top: 70px;
}
.padding-top-60px {
  padding-top: 60px;
}
.padding-top-50px {
  padding-top: 50px;
}
.padding-top-50px {
  padding-top: 50px;
}
.padding-top-45px {
  padding-top: 45px;
}
.padding-top-40px {
  padding-top: 40px;
}
.padding-top-35px {
  padding-top: 35px;
}
.padding-top-30px {
  padding-top: 30px;
}
.padding-top-20px {
  padding-top: 20px;
}
.padding-top-10px {
  padding-top: 10px;
}


.padding-bottom-200px {
  padding-bottom: 200px;
}
.padding-bottom-190px {
  padding-bottom: 190px;
}
.padding-bottom-180px {
  padding-bottom: 180px;
}
.padding-bottom-170px {
  padding-bottom: 170px;
}
.padding-bottom-160px {
  padding-bottom: 160px;
}
.padding-bottom-150px {
   padding-bottom: 150px;
}
.padding-bottom-140px {
    padding-bottom: 140px;
 }
.padding-bottom-130px {
  padding-bottom: 130px;
}
.padding-bottom-120px {
  padding-bottom: 120px;
}
.padding-bottom-110px {
  padding-bottom: 110px;
}
.padding-bottom-100px {
  padding-bottom: 100px;
}
.padding-bottom-95px {
  padding-bottom: 95px;
}
.padding-bottom-90px {
  padding-bottom: 90px;
}
.padding-bottom-85px {
  padding-bottom: 85px;
}
.padding-bottom-80px {
  padding-bottom: 80px;
}
.padding-bottom-70px {
  padding-bottom: 70px;
}
.padding-bottom-60px {
  padding-bottom: 60px;
}
.padding-bottom-50px {
  padding-bottom: 50px;
}
.padding-bottom-45px {
  padding-bottom: 45px;
}
.padding-bottom-40px {
  padding-bottom: 40px;
}
.padding-bottom-35px {
  padding-bottom: 35px;
}
.padding-bottom-30px {
  padding-bottom: 30px;
}
.padding-bottom-20px {
  padding-bottom: 20px;
}
.padding-bottom-10px {
  padding-bottom: 10px;
}

.padding-right-100px {
  padding-right: 100px;
}
.padding-right-95px {
  padding-right: 95px;
}
.padding-right-90px {
  padding-right: 90px;
}
.padding-right-85px {
  padding-right: 85px;
}
.padding-right-80px {
  padding-right: 80px;
}
.padding-right-70px {
  padding-right: 70px;
}
.padding-right-60px {
  padding-right: 60px;
}
.padding-right-55px {
  padding-right: 55px;
}
.padding-right-50px {
  padding-right: 50px;
}
.padding-right-45px {
  padding-right: 45px;
}
.padding-right-40px {
  padding-right: 40px;
}
.padding-right-30px {
  padding-right: 30px;
}

.padding-left-100px {
  padding-left: 100px;
}
.padding-left-95px {
  padding-left: 95px;
}
.padding-left-90px {
  padding-left: 90px;
}
.padding-left-85px {
  padding-left: 85px;
}
.padding-left-80px {
  padding-left: 80px;
}
.padding-left-70px {
  padding-left: 70px;
}
.padding-left-60px {
  padding-left: 60px;
}
.padding-left-55px {
  padding-left: 55px;
}
.padding-left-50px {
  padding-left: 50px;
}
.padding-left-45px {
  padding-left: 45px;
}
.padding-left-40px {
  padding-left: 40px;
}
.padding-left-30px {
  padding-left: 30px;
}

/*===== margin ====*/
.margin-top-200px {
   margin-top: 200px;
 }
.margin-top-190px {
   margin-top: 190px;
 }
.margin-top-180px {
   margin-top: 180px;
 }
.margin-top-160px {
   margin-top: 160px;
 }
.margin-top-150px {
   margin-top: 150px;
 }
.margin-top-140px {
   margin-top: 140px;
 }
.margin-top-130px {
   margin-top: 130px;
 }
.margin-top-120px {
   margin-top: 120px;
 }
.margin-top-110px {
  margin-top: 110px;
}
.margin-top-100px {
  margin-top: 100px;
}
.margin-top-95px {
  margin-top: 95px;
}
.margin-top-90px {
  margin-top: 90px;
}
.margin-top-85px {
  margin-top: 85px;
}
.margin-top-80px {
  margin-top: 80px;
}
.margin-top-70px {
  margin-top: 70px;
}
.margin-top-60px {
  margin-top: 60px;
}
.margin-top-55px {
  margin-top: 55px;
}
.margin-top-50px {
  margin-top: 50px;
}
.margin-top-45px {
  margin-top: 45px;
}
.margin-top-40px {
  margin-top: 40px;
}
.margin-top-35px {
  margin-top: 35px;
}
.margin-top-30px {
  margin-top: 30px;
}
.margin-top-20px {
  margin-top: 20px;
}
.margin-top-10px {
  margin-top: 10px;
}

.margin-bottom-200px {
  margin-bottom: 200px;
}
.margin-bottom-190px {
  margin-bottom: 190px;
}
.margin-bottom-180px {
  margin-bottom: 180px;
}
.margin-bottom-170px {
  margin-bottom: 170px;
}
.margin-bottom-160px {
  margin-bottom: 160px;
}
.margin-bottom-150px {
  margin-bottom: 150px;
}
.margin-bottom-140px {
  margin-bottom: 140px;
}
.margin-bottom-130px {
  margin-bottom: 130px;
}
.margin-bottom-120px {
  margin-bottom: 120px;
}
.margin-bottom-110px {
  margin-bottom: 110px;
}
.margin-bottom-100px {
  margin-bottom: 100px;
}
.margin-bottom-95px {
  margin-bottom: 95px;
}
.margin-bottom-90px {
  margin-bottom: 90px;
}
.margin-bottom-85px {
  margin-bottom: 85px;
}
.margin-bottom-80px {
  margin-bottom: 80px;
}
.margin-bottom-70px {
  margin-bottom: 70px;
}
.margin-bottom-60px {
  margin-bottom: 60px;
}
.margin-bottom-50px {
  margin-bottom: 50px;
}
.margin-bottom-55px {
  margin-bottom: 55px;
}
.margin-bottom-45px {
  margin-bottom: 45px;
}
.margin-bottom-40px {
  margin-bottom: 40px;
}
.margin-bottom-35px {
  margin-bottom: 35px;
}
.margin-bottom-30px {
  margin-bottom: 30px;
}
.margin-bottom-20px {
  margin-bottom: 20px;
}
.margin-bottom-10px {
  margin-bottom: 10px;
}

 .radius-round {
   @include border-radius(4px !important);
 }
.radius-rounded {
   @include border-radius(30px !important);
 }

.before-none:before {
  display: none !important;
}
.after-none:after {
  display: none !important;
}
.after-none:after,
.before-none:before {
  display: none !important;
}
.section-bg {
  background-color: $color-light-grey;
}
.section-bg-2 {
  background-color: $theme-color;
}

.section-block,
.section-block-2 {
  height: 1px;
}
.section-block {
  background-color: $color-light-grey;
}
.section-block-2 {
  background-color: $color;
}
.color-text {
  color: $theme-color-2 !important;
}
.color-text-2 {
  color: $theme-color !important;
}
.color-text-3 {
  color: $theme-color-5 !important;
}
.bg-img-transparent {
  background-image: none !important;
}
/*==== badge-closed ====*/
.badge-closed,
.section-bg-3 {
  background-color: $theme-color-2 !important;
}

.bg-1 {
    background-color: $color-3 !important;
}
.bg-2 {
    background-color: $color-4 !important;
}
.bg-3 {
    background-color: $color-5 !important;
}
.bg-4 {
    background-color: $color-6 !important;
}
.bg-5 {
  background-color: $alter-color-4 !important;
}
.bg-6 {
  background-color: $alter-color-2 !important;
}
/*=== p-relative p-absolute ====*/
.p-relative {
  position: relative;
}
.p-absolute {
  position: absolute;
}

/*========= preloader ==========*/
.loader-container {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: $color-light;
  top: 0;
  left: 0;
  z-index: 99999;
  .loader-ripple {
    position: relative;
    width: 64px;
    height: 64px;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));
    div {
      position: absolute;
      border: 4px solid $theme-color-2;
      opacity: 1;
      border-radius: 50%;
      animation: loader-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      &:nth-child(2) {
        animation-delay: -0.5s;
      }
    }
  }
}
/*====================================================
    tooltips
 ====================================================*/
.new-page-badge {
  display: inline-block;
  padding: 0 6px;
  background-color: $theme-color-5;
  color: $color-light;
  font-size: $default-font-6;
  @include border-radius(3px);
  line-height: 22px;
  float: right;
  font-weight: $font-weight-semi-bold;
}

.or-box {
  span {
    color: $theme-color;
    font-size: $display-9;
    font-weight: $font-weight-semi-bold;
    width: 60px;
    height: 60px;
    line-height: 60px;
    background-color: rgba(255, 107, 107, 0.1);
    display: inline-block;
    @include border-radius(50%);
  }
}
/*====================================================
    section-heading
 ====================================================*/
.section-heading {
  .sec__title {
    font-size: $primary-font;
    color: $theme-color;
    font-weight: $font-weight-bold;
    margin-bottom: 21px;
    position: relative;
    display: inline-block;
    padding-top: 35px;
    @media #{$large_mobile} {
     padding-top: 0;
    }
    @media #{$small_mobile} {
     padding-top: 0;
    }
    br {
       @media #{$large_mobile} {
        display: none;
      }
       @media #{$small_mobile} {
        display: none;
      }
    }
    @media #{$laptop_m_four} {
      font-size: $display-5;
    }
    &:before {
      position: absolute;
      content: "\f165";
      font-family: "LineAwesome";
      top: 0;
      left: 0;
      color: $theme-color-2;
      font-size: $display-11;
      @media #{$large_mobile} {
        display: none;
      }
      @media #{$small_mobile} {
        display: none;
      }
    }
    @media #{$large_mobile_three} {
      font-size: $display-10;
    }
    @media #{$small_mobile_four} {
      font-size: $display-13;
    }
    @media #{$small_mobile_three} {
      padding-top: 0;
    }
  }
  .sec__desc {
    font-size: $display-4;
    color: $theme-color-4;
    font-weight: $font-weight-medium;
    line-height: 28px;
    br {
      @media #{$tab_device} {
        display: none;
      }
      @media #{$large_mobile} {
        display: none;
      }
      @media #{$small_mobile} {
        display: none;
      }
    }
  }
}
.sec__list {
  li {
    display: inline-block;
    margin-left: 4px;
    &:first-child {
      margin-left: 0;
    }
    a {
      display: block;
      color: $theme-color-3;
      width: 45px;
      height: 45px;
      line-height: 45px;
      text-align: center;
      border: 2px solid rgba(140,67,255,0.1);
      @include border-radius(50%);
      font-size: $default-font;
      @include transition(0.3s);
      &:hover {
        background-color: $theme-color-3;
        color: $color-light;
        border-color: $theme-color-3;
      }
    }
  }
}
/*====================================================
    custom-checkbox
 ====================================================*/
.custom-checkbox {
  display: block;
  margin-bottom: 12px;
  @media #{$small_mobile_three} {
    margin-right: 0;
    display: block;
  }
  label {
    color: $theme-color-4;
    font-size: $default-font-2;
    font-weight: $font-weight-medium;
    display: block;
    margin-bottom: 0;
    text-transform: capitalize;
    cursor: pointer;
    span {
      float: right;
      background-color: rgba(128, 137, 150, 0.1);
      display: block;
      width: 26px;
      height: 26px;
      line-height: 26px;
      text-align: center;
      @include border-radius(30px);
      font-size: $default-font-4;
    }
  }
  input[type=checkbox]:not(:checked),
  input[type=checkbox]:checked {
    position: absolute;
    left: -9999px;
  }
  input[type=checkbox]:not(:checked) + label,
  input[type=checkbox]:checked + label {
    position: relative;
    padding-left: 26px;
  }
  input[type=checkbox]:not(:checked) + label:before,
  input[type=checkbox]:checked + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 3px;
    width: 18px;
    height: 18px;
    border: 1px solid $color-2;
    background-color: $color-light;
    @include border-radius(2px);
  }
  input[type=checkbox]:not(:checked) + label:after,
  input[type=checkbox]:checked + label:after {
    content: '\f00c';
    position: absolute;
    top: 1px;
    left: 3px;
    font-size: $default-font-5;
    color: $color-light;
    @include transition(0.3s);
    font-family: "FontAwesome", sans-serif;
  }
  input[type=checkbox]:not(:checked) + label:after {
    opacity: 0;
    visibility: hidden;
  }
  input[type=checkbox]:checked + label:after {
    opacity: 1;
    visibility: visible;
  }
  input[type=checkbox]:checked + label:before {
    background-color: $theme-color-2;
    border-color: $theme-color-2;
  }
}
/*====================================================
    select2-container
 ====================================================*/
.select2.select2-container {
  width: 100% !important;
}
.select2-container {
  .select2-selection--single {
    height: auto;
    padding: 10px 18px;
    border-color: rgba(128, 137, 150, 0.3);
    font-weight: $font-weight-medium;
    outline: none;
    text-align: left;
    .select2-selection__rendered {
      padding-left: 0;
      padding-right: 0;
      color: $theme-color-4;
    }
    .select2-selection__arrow {
      right: 12px;
      top: 50%;
      height: auto;
      @include transform(translateY(-50%));
      b {
        opacity: 0.8;
      }
    }
    .select2-selection__clear {
      margin-right: 15px;
      font-size: $display-3;
      color: $theme-color-2;
    }
  }
  &.select2-container--default {
    .select2-results {
      .select2-results__options {
        max-height: 228px;
      }
    }
    .select2-results__option[aria-selected="true"] {
      background-color: rgba(128, 137, 150, 0.2);
    }
    .select2-results__option--highlighted[aria-selected] {
      background-color: $theme-color-2;
    }
    .select2-dropdown {
      border-color: $color;
      .select2-search--dropdown {
        padding: 10px;
        .select2-search__field {
          border-color: rgba(128, 137, 150, 0.3);
          padding: 8px;
          @include border-radius(4px);
        }
      }
      .select2-results__option {
        padding: 10px 10px 10px 15px;
        margin-right: 10px;
        margin-left: 10px;
        @include border-radius(4px);
        font-weight: $font-weight-medium;
      }
    }
  }
}

.title-shape {
  width: 45px;
  height: 4px;
  @include border-radius(30px);
  background-color: $theme-color-2;
  position: relative;
  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    right: 13px;
    width: 5px;
    height: 5px;
    background-color: $color-light;
  }
}

/*======= social-profile ========*/
.social-profile {
  li {
    display: inline-block;
    a {
      display: block;
      color: $theme-color;
      width: 38px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background-color: rgba(128, 137, 150, 0.1);
      @include transition(0.3s);
      @include border-radius(50%);
      &:hover {
        background-color: $theme-color-2;
        color: $color-light;
      }
    }
  }
}
/*======= info-list ========*/
.info-list {
  li {
    display: block;
    font-size: $default-font-2;
    color: $theme-color-4;
    font-weight: $font-weight-medium;
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0;
    }
    a {
      color: $theme-color-4;
    }
    .la {
      color: $theme-color-2;
      margin-right: 2px;
      font-size: $default-font;
      width: 30px;
      height: 30px;
      background-color: rgba(255, 107, 107, 0.1);
      line-height: 30px;
      text-align: center;
      @include border-radius(50%);
      @include transition(0.3s);
    }
    &:hover {
      a {
        color: $theme-color-2;
      }
      .la {
        background-color: $theme-color-2;
        color: $color-light;
      }
    }
  }
}
/*======== img-boxes =========*/
.img-boxes {
  img {
    width: 100%;
    @include border-radius(12px);
  }
  .img-box-item.mt-4 {
    @media #{$large_mobile} {
      margin-top: 0 !important;
    }
    @media #{$small_mobile} {
      margin-top: 0 !important;
    }
  }
  .img-box-item {
    @media #{$large_mobile} {
     margin-bottom: 20px;
    }
    @media #{$small_mobile} {
     margin-bottom: 20px;
    }
  }
}
.mobile-img {
  img {
    width: 100%;
  }
}
/*======= list-items ========*/
.list-items {
  li {
    margin-bottom: 7px;
    font-weight: $font-weight-medium;
    color: $theme-color-4;
    font-size: $default-font-2;
    text-transform: capitalize;
    &:last-child {
      margin-bottom: 0;
    }
    a {
      color: $theme-color-4;
      @include transition(0.3s);
      position: relative;
      &:before {
        position: absolute;
        content: '';
        bottom: -2px;
        right: 0;
        width: 0;
        height: 2px;
        background-color: $theme-color-2;
        @include transition(0.3s);
      }
      &:hover {
        color: $theme-color-2;
        &:before {
          right: auto;
          left: 0;
          width: 100%;
        }
      }
    }
    span {
      font-size: $default-font-3;
      width: 25px;
      height: 25px;
      background-color: rgba(128, 137, 150, 0.1);
      @include border-radius(50%);
      text-align: center;
      line-height: 25px;
    }
  }
}
/*======== input-number-group =========*/
.input-number-group {
  p {
    color: $theme-color;
    font-size: $default-font-2;
    font-weight: $font-weight-medium;
    margin-bottom: 3px;
  }
  .input-group-button {
    display: inline-block;
    width: 28px;
    height: 28px;
    line-height: 26px;
    @include border-radius(50%);
    color: $theme-color;
    background-color: rgba(51, 63, 87, 0.1);
    font-size: $display-3;
    @include transition(0.3s);
    cursor: pointer;
    &:hover {
      color: $color-light;
      background-color: $theme-color-2;
    }
    span {
      line-height: 0;
    }
  }
  .input-number {
    border: 1px solid $color-2;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: $font-weight-medium;
    font-size: $default-font;
    @include border-radius(30px);
    color: $theme-color;
    outline: none;
    height: 35px;
    padding-left: 12px;
    @media #{$small_mobile_four} {
      padding: 0;
      width: 80px;
    }
  }
}
.tag-number {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  @include border-radius(50%);
  background-color: rgba(128,137,150,0.1);
  font-size: $default-font-3;
}

.tip {
  display: inline-block;
  cursor: help;
  @include border-radius(50%);
  color: $color-light;
  font-size: $default-font-2;
  line-height: 18px;
  width: 18px;
  height: 18px;
  text-align: center;
  background-color: $theme-color-2;
}
.tip-verified {
  background-color: $theme-color-5;
}
/*====================================================
    pagination-wrapper
 ====================================================*/
.pagination-list {
  li {
    display: inline-block;
    @media #{$small_mobile_five} {
      margin-bottom: 10px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &.pag__active {
      a {
        background-color: $theme-color-2;
        color: $color-light;
        border-color: $theme-color-2;
      }
    }
    a {
      position: relative;
      display: block;
      width: 40px;
      height: 40px;
      line-height: 40px;
      color: $theme-color-2;
      background-color: rgba(255, 107, 107, 0.1);
      @include border-radius(30px);
      @include transition(0.3s);
      font-size: $default-font;
      font-weight: $font-weight-semi-bold;
      &:hover {
        background-color: $theme-color-2;
        color: $color-light;
        border-color: $theme-color-2;
      }
    }
  }
}
/*======== generic-header =========*/
.generic-header {
  border: 1px solid rgba(128, 137, 150, 0.2);
  @include border-radius(4px);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  padding: 20px 20px;
  @media #{$large_mobile} {
    display: block;
  }
  @media #{$small_mobile} {
    display: block;
  }
  .generic-nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    @media #{$large_mobile} {
      margin-top: 20px;
    }
    @media #{$small_mobile} {
      margin-top: 20px;
    }
    li {
      a {
        display: block;
        color: $theme-color-2;
        margin-right: 4px;
        font-size: $display-3;
        width: 40px;
        height: 40px;
        background-color: rgba(255, 107, 107, 0.1);
        line-height: 40px;
        text-align: center;
        @include border-radius(50%);
        &.active {
          background-color: $theme-color-2;
          color: $color-light;
        }
      }
    }
  }
  .short-option {
    width: 200px;
    @media #{$large_mobile} {
      margin-right: 0 !important;
      margin-top: 10px;
    }
    @media #{$small_mobile} {
      margin-right: 0 !important;
      margin-top: 10px;
    }
  }
  .showing__text {
    flex: 5;
    color: $theme-color-4;
  }
}

/*======== top =========*/
#back-to-top {
  position: fixed;
  right: -150px;
  bottom: 40px;
  z-index: 9999;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  @include border-radius(50%);
  background-color: $color-light;
  @include box-shadow(0 10px 40px rgba(82, 85, 90, 0.2));
  color: $theme-color;
  font-size: $display-3;
  @include transition(0.4s);
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  &:hover {
    background-color: $theme-color-3;
    color: $color-light;
    border-color: $theme-color-3;
  }
  &.show-back-to-top {
    right: 30px;
    opacity: 1;
    visibility: visible;
  }
}





