/*====================================================
    single-listing-area
 ====================================================*/
.gallery-carousel {
  .gallery-item {
    @include border-radius(2px);
    position: relative;
    margin-bottom: 30px;
    img {
      width: 100%;
      @include border-radius(2px);
    }
  }
  .owl-nav {
    div {
      position: absolute;
      top: 41%;
      left: 10px;
      @include transform(translateY(-50%) scale(0.4));
      width: 45px;
      height: 45px;
      line-height: 45px;
      text-align: center;
      @include border-radius(50%);
      background-color: rgba(255, 107, 107, 0.7);
      color: $color-light;
      @include transition(0.3s);
      opacity: 0;
      visibility: hidden;
      &:hover {
        background-color: $theme-color-2;
      }
      &.owl-next {
        left: auto;
        right: 10px;
      }
      @media #{$small_mobile_two} {
        top: 18%;
        @include transform(translateY(0) scale(0.4));
        width: 40px;
        height: 40px;
        line-height: 40px;
      }
    }
  }
  .owl-dots {
    @media #{$small_mobile_four} {
      text-align: center;
    }
    .owl-dot {
      display: inline-block;
      margin-left: 5px;
      margin-right: 5px;
      @include border-radius(2px);
      opacity: 0.4;
      @include transition(0.3s);
      @media #{$laptop_m_four} {
        margin-bottom: 10px;
      }
      &.active,
      &:hover {
        opacity: 1;
      }
      img {
        width: 118px;
        @include border-radius(2px);
        @media #{$laptop_m_four} {
          width: 110px;
        }
        @media #{$tab_device} {
         width: 105px;
        }
      }
    }
  }
  &:hover {
    .owl-nav {
      div {
        @include transform(translateY(-50%) scale(1));
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
.feature-listing {
  .list-items {
    column-count: 3;
    @media #{$large_mobile} {
      column-count: 2;
    }
    @media #{$small_mobile} {
      column-count: 1;
    }
  }
}
.video-listing {
  .video__box {
    position: relative;
    @include border-radius(2px);
    img {
      width: 100%;
      @include border-radius(2px);
    }
    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(47, 38, 57, 0.6);
      @include border-radius(2px);
    }
  }
  .video__box-content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    @include transform(translate(-50%, -50%));
    z-index: 1;
    color: $color-light;
    .video__desc {
      text-transform: capitalize;
      font-size: $display-4;
      font-weight: $font-weight-medium;
      margin-top: 10px;
    }
  }
}
.contact-listing {
  .info-list {
    li {
      @media #{$small_mobile_two} {
        display: block;
      }
      span {
        color: $theme-color;
        width: 140px;
        display: inline-block;
        @media #{$small_mobile_two} {
          width: 100%;
          display: block;
          margin-bottom: 10px;
        }
      }
    }
  }
}
.social-contact {
  a {
    line-height: 45px;
    @media #{$laptop_m_four} {
      margin-bottom: 5px;
    }
    &:hover {
      @include transform(scale(1.04));
    }
    &.facebook-link {
      background-color: $alter-color;
    }
    &.twitter-link {
      background-color: $alter-color-2;
    }
    &.instagram-link {
      background-color: $alter-color-3;
    }
    &.linkedin-link {
      background-color: $alter-color-4;
    }
    &.youtube-link {
      background-color: $alter-color-5;
    }
  }
}

.single-listing-wrap {
  @media #{$tab_device} {
    margin-bottom: 60px;
  }
  @media #{$large_mobile} {
    margin-bottom: 60px;
  }
  @media #{$small_mobile} {
    margin-bottom: 60px;
  }
}