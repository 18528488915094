.comment {
  display: flex;
  margin-bottom: 30px;
  @media #{$large_mobile} {
    display: block;
    text-align: center;
  }
  @media #{$small_mobile} {
    display: block;
    text-align: center;
  }
  .avatar__img {
    width: 80px;
    height: 80px;
    @include border-radius(50%);
    border: 4px solid $color-light;
    @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
  }
  .comment-body {
    margin-left: 16px;
    @media #{$large_mobile} {
      margin-left: 0;
      margin-top: 15px;
    }
    .meta-data {
      position: relative;
    }
    .comment__author,
    .comment__date {
      display: block;
    }
    .comment__author {
      font-weight: $font-weight-semi-bold;
      color: $theme-color;
      text-transform: capitalize;
      font-size: $display-4;
    }
    .comment__date {
      font-size: $default-font-3;
      font-weight: $font-weight-medium;
    }
    .rating-rating {
      position: absolute;
      top: 3px;
      right: 0;
      @media #{$large_mobile} {
        position: inherit;
        justify-content: center;
        top: auto;
        right: auto;
      }
      @media #{$small_mobile} {
        position: inherit;
        justify-content: center;
        top: auto;
        right: auto;
      }
    }
    .comment-content {
      line-height: 25px;
      margin-top: 17px;
      margin-bottom: 15px;
      font-weight: $font-weight-medium;
    }
    .comment-reply {
      @media #{$large_mobile} {
        display: block !important;
      }
      @media #{$small_mobile} {
        display: block !important;
      }
      .comment__btn {
        color: $theme-color-2;
        line-height: 35px;
        @include border-radius(30px);
        padding-right: 20px;
        padding-left: 20px;
        background-color: transparent;
        @include box-shadow(0 0 0 0);
        &:hover {
          background-color: $theme-color-2;
          color: $color-light;
          border-color: $theme-color-2;
        }
      }
    }
  }
}
.feedback-box {
  font-size: $default-font-3;
  font-weight: $font-weight-medium;
  @media #{$large_mobile} {
    margin-top: 20px;
  }
  @media #{$small_mobile} {
    margin-top: 20px;
  }
  .theme-btn {
    border: 1px solid rgba(128, 137, 150, 0.3);
    line-height: 32px;
    padding-right: 12px;
    padding-left: 12px;
    color: $theme-color-4;
    margin-left: 3px;
    background-color: transparent;
    @include box-shadow(0 0 0 0);
    font-weight: $font-weight-medium;
    &:nth-child(2) {
      @media #{$small_mobile_two} {
        margin-top: 5px;
      }
    }
    i {
      font-size: $default-font;
    }
    &:hover {
      background-color: rgba(128, 137, 150, 0.1);
      color: $theme-color;
    }
  }
}
.comments-reply {
  margin-left: 95px;
  @media #{$laptop_m_four} {
    margin-left: 40px;
  }
  @media #{$large_mobile} {
    margin-left: 0;
  }
  @media #{$small_mobile} {
    margin-left: 0;
  }
}