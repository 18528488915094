.card-item {
  margin-bottom: 30px;
}
.card-image-wrap {
  color: $color-light;
  &:hover {
    color: $color-light;
  }
}
.author-img {
  width: 60px;
  height: 60px;
  @include border-radius(50%);
  border: 4px solid $color-light;
  @include box-shadow(0 10px 40px rgba(82, 85, 90, 0.2));
  position: absolute;
  right: 20px;
  top: 0;
  margin-top: -30px;
  img {
    @include border-radius(50%);
    width: 100%;
    height: 100%;
  }
}
.card-meta {
  font-size: $default-font-4;
  text-transform: capitalize;
  font-weight: $font-weight-semi-bold;
  position: relative;
  padding-left: 39px;
  color: $theme-color-4;
  a {
    color: $theme-color-4;
  }
  span {
    font-size: $display-3;
    position: absolute;
    top: -9px;
    left: 0;
    color: $color-light;
    width: 33px;
    height: 33px;
    background-color: $theme-color-2;
    line-height: 33px;
    text-align: center;
    @include border-radius(50%);
  }
}
.card-title {
  font-size: $display-4;
  color: $theme-color;
  font-weight: $font-weight-semi-bold;
  text-transform: capitalize;
  margin-top: 24px;
  margin-bottom: 5px;
  i {
    color: $theme-color-5;
  }
}
.card-sub {
  font-size: $default-font-2;
  color: $theme-color-4;
  font-weight: $font-weight-medium;
}
.card-image {
  position: relative;
  @include border-radius(4px 4px 0 0);
  .card__img {
    width: 100%;
    @include border-radius(4px 4px 0 0);
  }
  .badge {
    position: absolute;
    background-color: $theme-color-5;
    top: 15px;
    left: 21px;
    padding: 6px 11px;
    font-size: $default-font-5;
    text-transform: capitalize;
    @include border-radius(30px);
    font-weight: $font-weight-semi-bold;
    z-index: 1;
  }
  .badge-toggle {
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: $display-4;
    background-color: rgba(39, 43, 65, 0.6);
    padding: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    @include border-radius(50%);
    text-align: center;
    z-index: 1;
    &:hover {
      background-color: $theme-color-2;
    }
  }
  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: $theme-color;
    @include border-radius(4px 4px 0 0);
    @include transition(0.3s);
  }
}
.post-author {
  li {
    font-weight: $font-weight-semi-bold;
    color: $theme-color-4;
    font-size: $default-font-3;
    a {
      color: $theme-color-4;
      text-transform: capitalize;
      @include transition(0.3s);
      &:hover {
        color: $theme-color-2;
      }
    }
    img {
      width: 45px;
      height: 45px;
      @include border-radius(50%);
      border: 3px solid $color-light;
      margin-right: 3px;
      @include box-shadow(0 0 40px rgba(82, 85, 90, 0.2));
    }
  }
}
.card-content-wrap {
  position: relative;
  text-align: left;
  border: 1px solid $color-1;
  @include border-radius(0 0 4px 4px);
}
.card-content {
  padding: 30px 20px 25px 20px;
}
.rating-row {
  border-top: 1px solid $color-1;
  padding: 15px 20px 15px 24px;
  display: -webkit-box;
  display: -ms-flex;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: $theme-color-4;
  .edit-info-box {
    .theme-btn {
      font-size: $default-font-4;
      line-height: 35px;
      padding-right: 12px;
      padding-left: 12px;
      span {
        font-size: $default-font;
      }
    }
  }
}
.listing-info {
  ul {
    display: -webkit-box;
    display: -ms-flex;
    display: flex;
    li {
      margin-right: 12px;
      &:last-child {
        margin-right: 0;
      }
      span {
        font-size: $default-font;
        &.info__save {
          cursor: pointer;
          &:hover {
            color: $theme-color-2;
          }
        }
      }
    }
  }
}
.rating-rating {
  span {
    color: $color-5;
    margin-left: -4px;
    display: inline-block;
    font-size: $default-font-3;
    &.rating-count {
      margin-left: 0;
      color: $theme-color-4;
      font-weight: $font-weight-medium;
      font-size: $default-font-4;
    }
    &.last-star {
      color: $theme-color-4;
    }
  }
}
/*======= blog-card ========*/
.blog-card {
  border-bottom: 2px solid $color-light-grey;
  @include transition(0.3s);
  .card-title {
    font-size: $display-3;
    font-weight: $font-weight-bold;
    @include transition(0.3s);
    &:hover {
      color: $theme-color-2;
    }
  }
  &:hover {
    border-bottom-color: $theme-color-2;
  }
}
/*======= card-listing ========*/
.card-listing {
  @media #{$extra-device} {
    display: block !important;
  }
  .card-image-wrap {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    .card-image {
      @include border-radius(4px 0 0 4px);
      @media #{$extra-device} {
        @include border-radius(4px 4px 0 0);
      }
      .card__img {
        height: 324px;
        object-fit: cover;
        @include border-radius(4px 0 0 4px);
        @media #{$extra-device} {
          @include border-radius(4px 4px 0 0);
          object-fit: inherit;
          height: auto;
        }
      }
      &:after {
        @include border-radius(4px 0 0 4px);
        @media #{$extra-device} {
          @include border-radius(4px 4px 0 0);
        }
      }
    }
  }
  .card-content-wrap {
    -webkit-box-flex: 2;
    -webkit-flex: 2;
    -ms-flex: 2;
    flex: 2;
    @include border-radius(0 4px 4px 0);
    @media #{$extra-device} {
      @include border-radius(0 0 4px 4px);
    }
    .author-img {
      margin-top: 0;
      top: 20px;
    }
  }
}

/*======= card-carousel ========*/
.card-carousel {
  .card-item {
    @include transform(scale(0.9));
    margin-right: -10px;
    margin-left: -10px;
    @include transition(0.4s);
    @media #{$extra-device3} {
      margin-left: 0;
      margin-right: 0;
    }
  }
  .owl-item.active.center {
    .card-item {
      @include transform(scale(1));
      .card-image-wrap {
        .card-image {
          &:after {
            opacity: 0.2;
          }
        }
      }
    }
  }
  .owl-dots {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    div {
      width: 15px;
      height: 15px;
      @include border-radius(50%);
      border: 2px solid rgba(51, 63, 87, 0.1);
      display: inline-block;
      @include transition(0.3s);
      margin: 0 2px;
      @include transform(scale(0.8));
      &.active {
        @include transform(scale(1));
        background-color: $theme-color-2;
        border-color: $theme-color-2;
      }
    }
  }
}
.card-carousel.card-static {
  .card-item {
    @include transform(scale(1));
    margin-right: 0;
    margin-left: 0;
  }
}

